<template>
  <v-container fluid>
    <h4 class="mb-1">Imprint</h4>
    <p>
      Daniel McCharen <br />Marktstr. 31 <br />83646 Bad Tölz
      <br />cepheix2@gmail.com
    </p>
    <h4 class="mb-1">Liability for contents</h4>
    <p>
      As service providers, we are liable for own contents of these websites
      according to Sec. 7, paragraph 1 German Telemedia Act (TMG). However,
      according to Sec. 8 to 10 German Telemedia Act (TMG), service providers
      are not obligated to permanently monitor submitted or stored information
      or to search for evidences that indicate illegal activities. Legal
      obligations to removing information or to blocking the use of information
      remain unchallenged. In this case, liability is only possible at the time
      of knowledge about a specific violation of law. Illegal contents will be
      removed immediately at the time we get knowledge of them.
    </p>
    <h4 class="mb-1">Liability for links</h4>
    <p>
      Our offer includes links to external third party websites. We have no
      influence on the contents of those websites, therefore we cannot guarantee
      for those contents. Providers or administrators of linked websites are
      always responsible for their own contents. The linked websites had been
      checked for possible violations of law at the time of the establishment of
      the link. Illegal contents were not detected at the time of the linking. A
      permanent monitoring of the contents of linked websites cannot be imposed
      without reasonable indications that there has been a violation of law.
      Illegal links will be removed immediately at the time we get knowledge of
      them.
    </p>
    <h4 class="mb-1">Copyright</h4>
    <p>
      Contents and compilations published on these websites by the providers are
      subject to German copyright laws. Reproduction, editing, distribution as
      well as the use of any kind outside the scope of the copyright law require
      a written permission of the author or originator. Downloads and copies of
      these websites are permitted for private use only. The commercial use of
      our contents without permission of the originator is prohibited. Copyright
      laws of third parties are respected as long as the contents on these
      websites do not originate from the provider. Contributions of third
      parties on this site are indicated as such. However, if you notice any
      violations of copyright law, please inform us. Such contents will be
      removed immediately.
    </p>
    <h4 class="mb-1">Privacy Policy</h4>
    <p>
      The following information will provide you with an easy to navigate
      overview of what will happen with your personal data when you visit our
      website. The term “personal data” comprises all data that can be used to
      personally identify you. For detailed information about the subject matter
      of data protection, please consult our Data Protection Declaration, which
      we have included beneath this copy.
    </p>
    <h4 class="mb-1">Data Recording On Our Website</h4>
    <p>
      Who is the responsible party for the recording of data on this website
      (i.e. the “controller”)? The data on this website is processed by the
      operator of the website, whose contact information is available under
      section “Information Required by Law” on this website.
    </p>
    <h4 class="mb-1">How do we record your data?</h4>
    <p>
      We collect your data as a result of your sharing of your data with us.
      This may, for instance be information you enter into our contact form. Our
      IT systems automatically record other data when you visit our website.
      This data comprises primarily technical information (e.g. web browser,
      operating system or time the site was accessed). This information is
      recorded automatically when you access our website.
    </p>
    <h4 class="mb-1">What Are the Purposes We Use Your Data For?</h4>
    <p>
      A portion of the information is generated to guarantee the error free
      provision of the website. Other data may be used to analyse your user
      patterns.
    </p>
    <h4 class="mb-1">
      What Rights Do You Have as Far as Your Information Is Concerned?
    </h4>
    <p>
      You have the right to receive information about the source, recipients and
      purposes of your archived personal data at any time without having to pay
      a fee for such disclosures. You also have the right to demand that your
      data are rectified, blocked or eradicated. Please do not hesitate to
      contact us at any time under the address disclosed in section “Information
      Required by Law” on this website if you have questions about this or any
      other data protection related issues. You also have the right to log a
      complaint with the competent supervising agency. Moreover, under certain
      circumstances, you have the right to demand the restriction of the
      processing of your personal data. For details, please consult the Data
      Protection Declaration under section “Right to Restriction of Data
      Processing.”
    </p>
    <h4 class="mb-1">Analysis Tools and Tools Provided by Third Parties</h4>
    <p>
      There is a possibility that your browsing patterns will be statistically
      analysed when your visit our website. Such analyses are performed
      primarily with cookies and with what we refer to as analysis programmes.
      As a rule, the analyses of your browsing patterns are conducted
      anonymously; i.e. the browsing patterns cannot be traced back to you. You
      have the option to object to such analyses or you can prevent their
      performance by not using certain tools. For detailed information about
      this, please consult our Data Protection Declaration below. You do have
      the option to object to such analyses. We will brief you on the objection
      options in this Data Protection Declaration.
    </p>
    <h4 class="mb-1">Data Protection</h4>
    <p>
      The operators of this website and its pages take the protection of your
      personal data very seriously. Hence, we handle your personal data as
      confidential information and in compliance with the statutory data
      protection regulations and this Data Protection Declaration. Whenever you
      use this website, a variety of personal information will be collected.
      Personal data comprises data that can be used to personally identify you.
      This Data Protection Declaration explains which data we collect as well as
      the purposes we use this data for. It also explains how, and for which
      purpose the information is collected. We herewith advise you that the
      transmission of data via the Internet (i.e. through e-mail communications)
      may be prone to security gaps. It is not possible to completely protect
      data against third party access. Information about the responsible party
      (referred to as the “controller” in the GDPR)
      <br /><br />
      The data processing controller on this website is:
      <br /><br />
      Daniel McCharen <br />Marktstr. 31 <br />83646 Bad Tölz
      <br />cepheix2@gmail.com <br /><br />
      The controller is the natural person or legal entity that single-handedly
      or jointly with others makes decisions as to the purposes of and resources
      for the processing of personal data (e.g. names, e-mail addresses, etc.).
    </p>
    <h4 class="mb-1">Revocation of Your Consent to the Processing of Data</h4>
    <p>
      A wide range of data processing transactions are possible only subject to
      your express consent. You can also revoke at any time any consent you have
      already given us. To do so, all you are required to do is sent us an
      informal notification via e-mail. This shall be without prejudice to the
      lawfulness of any data collection that occurred prior to your revocation.
    </p>
    <h4 class="mb-1">
      Right to Object to the Collection of Data in Special Cases; Right to
      Object to Direct Advertising (Art. 21 Gdpr)
    </h4>
    <p>
      In the event that data are processed on the basis of Art. 6 Sect. 1 lit. e
      or f GDPR, you have the right to at any time object to the processing of
      your personal data based on grounds arising from your unique situation.
      This also applies to any profiling based on these provisions. To determine
      the legal basis, on which any processing of data is based, please consult
      this Data Protection Declaration. If you log an objection, we will no
      longer process your affected personal data, unless we are in a position to
      present compelling protection worthy grounds for the processing of your
      data, that outweigh your interests, rights and freedoms or if the purpose
      of the processing is the claiming, exercising or defence of legal
      entitlements (objection pursuant to Art. 21 Sect. 1 GDPR). If your
      personal data is being processed in order to engage in direct advertising,
      you have the right to at any time object to the processing of your
      affected personal data for the purposes of such advertising. This also
      applies to profiling to the extent that it is affiliated with such direct
      advertising. If you object, your personal data will subsequently no longer
      be used for direct advertising purposes (objection pursuant to Art. 21
      Sect. 2 GDPR).
    </p>
    <h4 class="mb-1">
      Right to Log a Complaint with the Competent Supervisory Agency
    </h4>
    <p>
      In the event of violations of the GDPR, data subjects are entitled to log
      a complaint with a supervisory agency, in particular in the member state
      where they usually maintain their domicile, place of work or at the place
      where the alleged violation occurred. The right to log a complaint is in
      effect regardless of any other administrative or court proceedings
      available as legal recourses.
    </p>
    <h4 class="mb-1">Right to Data Portability</h4>
    <p>
      You have the right to demand that we hand over any data we automatically
      process on the basis of your consent or in order to fulfil a contract be
      handed over to you or a third party in a commonly used, machine readable
      format. If you should demand the direct transfer of the data to another
      controller, this will be done only if it is technically feasible.
    </p>
    <h4 class="mb-1">SSL and/or TLS Encryption</h4>
    <p>
      For security reasons and to protect the transmission of confidential
      content, such as purchase orders or inquiries you submit to us as the
      website operator, this website uses either an SSL or a TLS encryption
      programme. You can recognise an encrypted connection by checking whether
      the address line of the browser switches from “http://” to “https://” and
      also by the appearance of the lock icon in the browser line. If the SSL or
      TLS encryption is activated, data you transmit to us cannot be read by
      third parties.
    </p>
    <h4 class="mb-1">
      Information about, Blockage, Rectification and Eradication of Data
    </h4>
    <p>
      Within the scope of the applicable statutory provisions, you have the
      right to at any time demand information about your archived personal data,
      their source and recipients as well as the purpose of the processing of
      your data. You may also have a right to have your data rectified, blocked
      or eradicated. If you have questions about this subject matter or any
      other questions about personal data, please do not hesitate to contact us
      at any time at the address provided in section “Information Required by
      Law.”
    </p>
    <h4 class="mb-1">Right to Demand Processing Restrictions</h4>
    <p>
      You have the right to demand the imposition of restrictions as far as the
      processing of your personal data is concerned. To do so, you may contact
      us at any time at the address provided in section “Information Required by
      Law.” The right to demand restriction of processing applies in the
      following cases:
    </p>
    <ul>
      <li>
        In the event that you should dispute the correctness of your data
        archived by us, we will usually need some time to verify this claim.
        During the time that this investigation is ongoing, you have the right
        to demand that we restrict the processing of your personal data.
      </li>
      <li>
        If the processing of your personal data was/is conducted in an unlawful
        manner, you have the option to demand the restriction of the processing
        of your data in lieu of demanding the eradication of this data.
      </li>
      <li>
        If we do not need your personal data any longer and you need it to
        exercise, defend or claim legal entitlements, you have the right to
        demand the restriction of the processing of your personal data instead
        of its eradication.
      </li>
      <li>
        If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your
        rights and our rights will have to be weighed against each other. As
        long as it has not been determined whose interests prevail, you have the
        right to demand a restriction of the processing of your personal data.
      </li>
      <li>
        If you have restricted the processing of your personal data, these data
        – with the exception of their archiving – may be processed only subject
        to your consent or to claim, exercise or defend legal entitlements or to
        protect the rights of other natural persons or legal entities or for
        important public interest reasons cited by the European Union or a
        member state of the EU.
      </li>
    </ul>
    <br />
    <h4 class="mb-1">Server Log Files</h4>
    <p>
      The provider of this website and its pages automatically collects and
      stores information in so-called server log files, which your browser
      communicates to us automatically. The information comprises:
    </p>
    <ul>
      <li>The type and version of browser used</li>
      <li>The used operating system</li>
      <li>Referrer URL</li>
      <li>The hostname of the accessing computer</li>
      <li>The time of the server inquiry</li>
      <li>The IP address</li>
    </ul>
    <br />
    <p>
      This data is not merged with other data sources. This data is recorded on
      the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of the website has a
      legitimate interest in the technically error free depiction and the
      optimization of the operator’s website. In order to achieve this, server
      log files must be recorded.
    </p>
    <h4 class="mb-1">Request by E-mail, Telephone or Fax</h4>
    <p>
      If you contact us by e-mail, telephone or fax, your request, including all
      resulting personal data (name, request) will be stored and processed by us
      for the purpose of processing your request. We do not pass these data on
      without your consent. The processing of these data is based on Art. 6
      para. 1 lit. b GDPR, if your request is related to the execution of a
      contract or if it is necessary to carry out pre-contractual measures. In
      all other cases, the processing is based on your consent (Article 6 (1) a
      GDPR) and/or on our legitimate interests (Article 6 (1) (f) GDPR), since
      we have a legitimate interest in the effective processing of requests
      addressed to us. The data sent by you to us via contact requests remain
      with us until you request us to delete, revoke your consent to the storage
      or the purpose for the data storage lapses (e.g. after completion of your
      request). Mandatory statutory provisions - in particular statutory
      retention periods - remain unaffected.
    </p>
  </v-container>
</template>

<script>
export default {
  name: "Imprint",
};
</script>

<style scoped lang="scss">
* {
  line-height: 1.4;
}
.container {
  // max-width: 850px;
  padding: 16px;
}

h4 {
  font-weight: 600;
}
</style>
