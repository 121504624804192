<template>
  <v-container fluid>
    <!-- <h1 class="mb-1" style="color: #18191a">Herzlich Willkommen</h1>
    <h3 class="mb-4" style="color: #18191a">
      Mobile Physiotherapie Sebastian Wittmann
    </h3> -->
    <v-row v-for="(w, index) in work" :key="index" justify="start">
      <!-- <embed
        src="https://cepheicephei.github.io/website-background/src/"
        type="text/html"
        width="100%"
        height="240px"
      /> -->

      <v-col cols="12" md="4">
        <v-img
          :lazy-src="w.imageUrl"
          class="mr-0"
          style="border-radius: 4px;"
          max-height="280px"
          fill
          :src="w.imageUrl"
        />
      </v-col>

      <v-col cols="12" md="8">
        <div class="d-flex justify-space-between">
          <h4 class="mb-4" style="font-weight: 600">{{ w.title }}</h4>
          <p
            style="
              border: 1px solid #18191a;
              border-radius: 4px;
              padding: 4px;
              width: fit-content;
              font-size: 0.8em;
            "
          >
            {{ w.projectTime }}
          </p>
        </div>
        <p>
          {{ w.description }}
        </p>
        <a
          v-for="(l, index) in w.projectLinks"
          :href="l.link"
          target="_blank"
          style="text-decoration: none; color: #1976d2"
          :key="index"
          >{{ l.text }}
          <v-icon small color="#1976d2">mdi-open-in-new</v-icon>
        </a>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" sm="6"> </v-col>
      <v-col class="d-sm-none" cols="12" sm="6">
        <v-img
          lazy-src="require('../assets/fp2.jpg')"
          class="mr-0"
          contain
          :src="require('../assets/fp2.jpg')"
        />
      </v-col>
      <v-col class="d-none d-sm-block" cols="12" sm="6">
        <v-img
          lazy-src="require('../assets/fp2.jpg')"
          class="mr-0"
          contain
          :src="require('../assets/fp2.jpg')"
        />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      work: [
        {
          title: "Penplotter",
          slug: "penlotter",
          description:
            "A homemade pen plotter using 3D printed parts, an Arduino running GRBL and UGS to send gcode to the machine. Checkout my Instagram for videos and photos. Also check my shop (coming soon) if you want to spice up that empty space on the wall.",
          projectTime: "2020",
          projectLinks: [
            { text: "See it in action", link: "https://youtu.be/ujDT_dPZTsc" },
          ],
          imageUrl:
            "https://uploads-ssl.webflow.com/605f76ec5c469644cb753410/60aaab82f39184cb0bbee58e_IMG_3338.jpg",
        },
        {
          title: "Marble Track",
          slug: "marble-track",
          description:
            "A marble track with DC motor powered marble lifting mechanism. Heavily inspired by the works of Daniel De Bruin.",
          projectTime: "2020",
          projectLinks: [
            { text: "Watch Video", link: "https://youtu.be/1_L1ZG7WadY" },
          ],
          imageUrl:
            "https://uploads-ssl.webflow.com/605f82200b0de37cb0759405/60ab7e9a6c471775ef1fd010_Marble%20Machine%20Gif.gif",
        },
        {
          title: "One-handed Guitar Helper",
          slug: "one-handed-guitar-helper",
          description:
            "A mechanism to enable guitar playing for people with only one functioning hand, created for my bachelor's thesis. The image is one of the renderings I did. Video will be added at a later date.",
          projectTime: "2020",
          projectLinks: [
            {
              text: "Read the thesis (German)",
              link: "https://uploads-ssl.webflow.com/605f82200b0de37cb0759405/60ab8e5938f72fa46061c63c_Bachelorarbeit_McCharen.pdf",
            },
          ],
          imageUrl:
            "https://uploads-ssl.webflow.com/605f82200b0de37cb0759405/606253eb4795c8a1d15fa22f_Guitar.png",
        },
        {
          title: "CNC font generator for Illustrator",
          slug: "cnc-font-generator-for-illustrator",
          description:
            "In need of a single-path font generator, I decided to write my own. I created an Adobe Illustrator extension, the text generated consists of single line paths and can be worked with in Illustrator or exported as SVG for external use. More info can be found on the Github repo.",
          projectTime: "2022",
          projectLinks: [
            {
              text: "View project on Github",
              link: "https://github.com/cepheicephei/single-line-font-generator-for-ai",
            },
          ],
          imageUrl:
            "https://uploads-ssl.webflow.com/605f82200b0de37cb0759405/6292842ca87fbcaf34797065_preview.png",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
* {
  line-height: 1.4;
}
.container {
  // max-width: 850px;
  padding: 16px;
}
</style>
