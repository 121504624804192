<template>
  <v-card style="padding: 0">
    <v-app-bar app elevation="0" color="white" style="padding: 0">
      <v-container fluid d-flex style="padding: 0px 16px">
        <router-link style="text-decoration: none !important" to="/">
          <!-- <img class="mr-3" :src="require('../assets/logo.svg')" width="200px" /> -->
          <h4
            style="
              font-weight: 600;
              text-decoration: none !important;
              color: #18191a;
            "
          >
            Daniel McCharen
          </h4>
        </router-link>
        <v-spacer></v-spacer>
        <!-- <v-app-bar-nav-icon
          class="d-md-none"
          @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon> -->
        <v-toolbar-items class="align-center">
          <router-link class="link mr-4" to="/info">Info</router-link>
          <a
            href="https://www.instagram.com/cepheicephei"
            target="_blank"
            style="text-decoration: none; color: #18191a"
          >
            <h4 style="font-weight: 400">
              <v-icon color="#18191a">mdi-instagram</v-icon>
            </h4>
          </a>
        </v-toolbar-items>
        <!-- <v-toolbar-items class="d-none d-md-flex align-center">
          <router-link class="link mr-4" to="/info">Info</router-link>
        </v-toolbar-items> -->
      </v-container>
    </v-app-bar>
    <!-- <v-navigation-drawer app v-model="drawer" temporary right>
        <v-list nav dense>
          <v-list-item-group v-model="group">
            <v-list-item class="mobilelink" to="/info">Info</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer> -->
  </v-card>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    // drawer: false,
    // group: null,
  }),

  // watch: {
  //   group() {
  //     this.drawer = false;
  //   },
  // }
};
</script>

<style scoped>
.link {
  color: #18191a;
  text-decoration: none !important;
  font-weight: 600;
}
.mobilelink {
  color: #18191a !important;
  text-decoration: none !important;
  font-weight: 600;
}

>>> .v-toolbar__content {
  padding: 0 !important;
}
</style>
