<template>
  <v-container fluid>
    <!-- <h1 class="mb-3" style="color: #18191a">Sebastian Wittmann</h1> -->
    <v-row>
      <!-- <v-col cols="2">
        <v-img
          lazy-src="require('../assets/detail-1.jpg')"
          class="mr-0"
          contain
          :src="require('../assets/detail-1.jpg')"
        />
      </v-col> -->

      <v-col>
        <p>
          Daniel McCharen (*95) is an audiodesigner turned developer working as
          a product developer for a tech startup during the day. In his free
          time he creates art and installations using hardware, software and
          materials such as wood, metal and plastic.
        </p>
        <p>
          email: <a href="mailto:cepheix2@gmail.com" style="text-decoration: none; color: #1976d2">cepheix2@gmail.com</a><br />
          instagram: <a href="https://www.instagram.com/cepheicephei" target="_blank" style="text-decoration: none; color: #1976d2">@cepheicephei</a><br />
          youtube: <a href="https://www.youtube.com/@cepheicephei3410" target="_blank" style="text-decoration: none; color: #1976d2">cepheicephei</a>
        </p>
      </v-col>
    </v-row>
    <!-- <h3 class="mb-4">
      {{ workExperience.title }}
    </h3>
    <v-simple-table
      class="mb-4"
      dense
      v-for="e in workExperience.entries"
      :key="e.position"
    >
      <tbody>
        <tr>
          <td width="140px">{{ e.time }}</td>
          <td>
            <strong>{{ e.position }}</strong> — {{ e.info }}
          </td>
        </tr>
        <tr v-for="c in e.content" :key="c">
          <td></td>
          <td>— {{ c }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 class="mb-4">
      {{ education.title }}
    </h3>
    <v-simple-table
      class="mb-4"
      dense
      v-for="e in education.entries"
      :key="e.position"
    >
      <tbody>
        <tr>
          <td width="140px">{{ e.time }}</td>
          <td>
            <strong>{{ e.position }}</strong> — {{ e.info }}
          </td>
        </tr>
        <tr v-for="c in e.content" :key="c">
          <td></td>
          <td>— {{ c }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 class="mb-4">
      {{ projects.title }}
    </h3>
    <v-simple-table
      class="mb-4"
      dense
      v-for="e in projects.entries"
      :key="e.position"
    >
      <tbody>
        <tr>
          <td width="140px">{{ e.time }}</td>
          <td>
            <strong>{{ e.position }}</strong> — {{ e.info }}
          </td>
        </tr>
        <tr v-for="c in e.content" :key="c">
          <td></td>
          <td>— {{ c }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 class="mb-4">
      {{ skillsAndInterests.title }}
    </h3>
    <v-simple-table
      dense
      v-for="c in skillsAndInterests.content"
      :key="c"
    >
      <tbody>
        <tr>
          <td width="140px">
            <strong>{{ c.title }}</strong>
          </td>
          <td>
            {{ c.info }}
          </td>
        </tr>
      </tbody>
    </v-simple-table> -->
  </v-container>
</template>

<script>
export default {
  name: "Info",
  data: function () {
    return {
      workExperience: {
        title: "Work Experience",
        entries: [
          {
            time: "2021 — 2022",
            position: "Product Developer",
            info: "Render Technology UG (haftungsbeschränkt), Berlin",
            content: [
              "Streaming client design, Vue.js (Nuxt.js)",
              "Backend and database architecture and implementation (Node.js, MongoDB)",
              "Design and implementation of the landing page and CMS system",
              "Defining product specifications and technologies",
            ],
          },
          {
            time: "2017 — current",
            position: "Web Development, Audio- & Graphic Design",
            info: "Freelance work",
            content: [
              "Web design and development for various clients",
              "Music composition and production for film and Instagram advertising, Birkenstock GmbH, ZDF",
              "Graphic design for multiple music artists",
            ],
          },
          {
            time: "2017 — 2018",
            position: "Volumetric video and 3D Audio Internship",
            info: "Volucap GmbH, Babelsberg",
            content: [
              "Working with Unity (C#) and Protools for various film and advertising projects",
            ],
          },
        ],
      },
      education: {
        title: "Education",
        entries: [
          {
            time: "2015 — 2020",
            position: "B.A. Audiodesign",
            info: "SRH University of applied sciences, Berlin",
            content: [
              "Focus on prototyping, signal processing and audio programming",
              "Projects on Arduino and web development (C++, Javascript) and sound synthesis (Max/MSP)",
              "Bachelor's Thesis: Strumming and picking device for guitars that is controlled via foot pedals, enabling persons with only one functioning hand to play a guitar (Modelling, 3D printing, Arduino and Javascript development)",
            ],
          },
          {
            time: "2013",
            position: "Abitur (A levels)",
            info: "Gabriel v. Seidl Gymnasium, Bad Tölz",
            content: ["3 years abroad in USA"],
          },
        ],
      },
      projects: {
        title: "Projects",
        entries: [
          {
            time: "2020 — 2021",
            position: "Hardware Design and Development",
            info: "Personal interest",
            content: [
              "Arduino based, highly accurate CNC drawing machine",
              "3D-printed, foot-controlled guitar controller using Arduino and Javascript",
            ],
          },
        ],
      },
      skillsAndInterests: {
        title: "Skills and Interests",
        content: [
          {
            title: "Languages",
            info: "English (native), German (native), French (B1)",
          },
          {
            title: "Technology",
            info: "Javascript (Nodejs, Vue), C#, Python, Arduino, Raspberry Pi",
          },
          {
            title: "Software",
            info: "VSCode, Figma, Adobe CC, Blender, Unity 5, Ableton Live",
          },
          {
            title: "Interests",
            info: "Woodworking, art, cooking",
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  // max-width: 850px;
  padding: 16px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 1em;
  padding-left: 0;
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border: none;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border: none;
}
/* .text-left {
  line-height: 1.5;
}

@media only screen and (min-width: 960px) {
  .text-left {
    line-height: 1.3;
  }
} */
</style>
