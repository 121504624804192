<template>
  <v-app>
    <Navbar />
    <v-main class="pt-2">
      <v-content>
        <router-view />
      </v-content>
    </v-main>
    <Footer />
  </v-app>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sometype+Mono:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

#app {
  font-family: Outfit, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #18191a;
  font-size: 1.1em;

  // background: url('assets/logo_only_low_opacity.png') no-repeat center center fixed !important;
  // background-size: cover !important;
  // background-attachment: fixed !important;
}

.container {
  text-align: left;
  max-width: 1200px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: Sometype Mono, Arial, sans-serif;
}
</style>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
