<template>
  <v-container fluid>
    <h1>404 Page Not Found</h1>
    <router-link style="text-decoration: none !important" to="/">
      <!-- <img class="mr-3" :src="require('../assets/logo.svg')" width="200px" /> -->
      <h4
        style="
          font-weight: 600;
          text-decoration: none !important;
          text-decoration: underline;
          color: #18191a;
        "
      >
        Go back home
      </h4>
    </router-link>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  // max-width: 850px;
  padding: 16px;
}
</style>
