<template>
  <v-card height="120px" flat color="transparent">
    <v-footer absolute padless="padless">
      <v-card flat tile width="100%" class="bg-custom">
        <!-- <v-card-text class="white--text">
          <h2 class="my-4">Daniel McCharen</h2>
          <h4 class="mb-4" style="font-weight: 600">
            —  —  —  —
          </h4>
          <h4 style="font-weight: 400">
            <v-icon color="white">mdi-phone-outline</v-icon> +49 160 97396785
          </h4>
          <h4 style="font-weight: 400">
            <v-icon color="white">mdi-email-outline</v-icon>
            physiowittmann@gmail.com
          </h4>
          <a href="https://www.instagram.com/physio_swittmann" target="_blank" style="text-decoration: none; color: white;">
            <h4 style="font-weight: 400">
              <v-icon color="white">mdi-instagram</v-icon> physio_swittmann
            </h4>
          </a>
        </v-card-text> -->

        <!-- <v-divider></v-divider> -->

        <v-card-text class="white--text">
          © {{ new Date().getFullYear() }} Daniel McCharen —
          <router-link class="link" to="/imprint">Imprint</router-link>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.link {
  color: #ffffff;
}

.bg-custom {
  background-color: #18191a;
}
</style>
